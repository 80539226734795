// Create the <style> tag
const style = document.createElement('style');

// Add a media (and/or media query) here if you'd like!
// style.setAttribute("media", "screen")
// style.setAttribute("media", "only screen and (max-width : 1024px)")

// WebKit hack :(
style.appendChild(document.createTextNode(''));

// Add the <style> element to the page
document.head.appendChild(style);

const { sheet } = style;

sheet.insertRule(`
  h1 {
    color: #333333;
    font-size: 36px;
  }
`, 0);

sheet.insertRule(`
  /* sub header h2 */
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #333333;
  }
`, 0);

sheet.insertRule(`
  h2 {
    font-size: 20px;
  }
`, 0);

sheet.insertRule(`
  h3 {
    margin-top: 28px;
    margin-bottom: 8px;
    font-size: 16px;
  }
`, 0);

sheet.insertRule(`
  h5 {
    margin-bottom: 8px;
    font-size: 14px;
  }
`, 0);

sheet.insertRule(`
  p {
    margin: 8px 0;
    font-size: 14px;
  }
`, 0);

sheet.insertRule(`
  li {
    font-size: 14px;
  }
`, 0);
