export const primary = '#008542';

export const secondary = '#3a982c';

export const alternate = '#ffffff';

export const wallpaper = '#f9f9f9';

export const header = '#333333';

export const bodyCopy = '#272727';
