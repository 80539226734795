import Prismic from 'prismic-javascript';

const apiEndpoint = window.prismicEndpoint;

const query = ({ type, id }) => Prismic.getApi(apiEndpoint).then((api) => {
  const param = typeof type === 'undefined'
    ? Prismic.Predicates.at('document.id', id)
    : Prismic.Predicates.at('document.type', type);

  return api.query(param);
});

export default ({ type, id }) => new Promise((resolve, reject) => {
  query({ type, id }).then((res) => {
    resolve(res.results);
  }).catch((err) => {
    reject(err);
  });
});
