// @flow
import React, { Suspense } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { withRouter } from 'react-router';

import { wallpaper } from '@site/lib/colors';

import prismic from '@site/services/prismic';

const Body = React.lazy(
  () => import(/* webpackChunkName: "body" */ './Body'),
);

const Header = React.lazy(
  () => import(/* webpackChunkName: "header" */ './Header'),
);

const Footer = React.lazy(
  () => import(/* webpackChunkName: "footer" */ './Footer'),
);

type Props = {
  history: {
    listen: Function,
  },
};

type State = {
  categories: Array<{}>,
}

class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      categories: [],
    };

    prismic({ type: 'rg-blog-header' }).then((res) => {
      this.setState({
        categories: res[0].data.categories,
      });
    });

    props.history.listen(({ hash }) => {
      if (hash === '') {
        window.scrollTo(0, 0);
      }
    });
  }

  render() {
    const styles = StyleSheet.create({
      container: {
        backgroundColor: wallpaper,
      },
    });

    return (
      <Suspense fallback={<div />}>
        <div className={css(styles.container)}>
          <Header
            categories={this.state.categories}
          />
          <Body
            categories={this.state.categories}
          />
          <Footer />
        </div>
      </Suspense>
    );
  }
}

export default withRouter(App);
