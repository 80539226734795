import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import rootDomTag from '@site/lib/rootDomTag';

import App from './App';

import './styles';

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById(rootDomTag),
);
